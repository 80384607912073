<script lang="ts" setup>
import SvgIcon from "~/components/Common/SvgIcon.vue";
import TransitionHeight from "~/components/Common/TransitionHeight.vue";
import { useTeamsCookies } from "~/composables/cookies/useTeamsCookies";
import { useOffCatalogRequests } from "~/store/catalog/off-catalog-requests.store";
import { useCreateRequestStore } from "~/store/requests/create-request.store";

const emit = defineEmits(["update:open"]);
const teamsCookies = useTeamsCookies();


const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const offCatalogRequestsStore = useOffCatalogRequests();
const createRequestStore = useCreateRequestStore();

async function redirectToTrainings() {
  await teamsCookies.setQueryOpts("filter", 'request', 'my_trainings_opts');
  await teamsCookies.setQueryOpts("offset", 0, 'my_trainings_opts');
  await navigateTo("/my-trainings");
}


function openMenu() {
  emit("update:open", !props.open);
}
</script>

<template>
  <div class="flex flex-col gap-4 md:hidden">
    <TransitionHeight :duration="300">
      <div v-if="open" class="flex flex-col gap-4">
        <span class="divider__grasp" @click="(event: MouseEvent) => emit('update:open', false)"></span>
        <div class="px-10">
          <div class="home__button_container body_demi">
            <NuxtLink class="home__button button_border__default text-primary-blue-color" :to="'catalog'">
              <SvgIcon name="search" class="w-[16px] h-[16px] items-center svg_icon"></SvgIcon>

              <span class="body_med">Parcourir le catalogue</span>
            </NuxtLink>
            <button
              class="home__button button_border__default text-primary-blue-color"
              @click="
                offCatalogRequestsStore.$reset();
                createRequestStore.$reset();
                offCatalogRequestsStore.showModal = true;
              "
            >
              <SvgIcon name="request" class="w-[16px] h-[16px] items-center svg_icon"></SvgIcon>

              <span class="body_med">Faire une demande hors catalogue</span>
            </button>
            <button class="home__button button_border__default text-primary-blue-color" @click="redirectToTrainings">
              <SvgIcon name="complete" class="w-[16px] h-[16px] items-center svg_icon"></SvgIcon>
              <span class="body_med">Suivre mes demandes</span>
            </button>
          </div>
        </div>
        <span class="divider__default"></span>
      </div>
    </TransitionHeight>
    <div class="px-10">
      <button
        class="home__button button__default bg-primary-blue-color hover:!bg-primary-blue-color w-full flex gap-1"
        @click="openMenu"
      >
        <Transition name="slide-up" mode="out-in">
          <SvgIcon v-if="open" class="svg_icon" name="close"></SvgIcon>
          <SvgIcon v-else class="svg_icon" name="add"></SvgIcon>
        </Transition>
        <span class="body_demi">Actions</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home__button {
  @apply flex justify-center items-center gap-[8px];
}

.home__button_container {
  @apply flex flex-row items-center flex-wrap gap-[8px];
}

@media (max-width: 640px) {
  .home__button_container {
    @apply flex-col w-full;

    .home__button {
      @apply w-full;
    }
  }
}

.divider__default {
  @apply flex border-b-[4px] border-light-grey-color justify-center items-center rounded-[4px];
}

.divider__grasp {
  @apply flex border-b-[4px] border-gray-200 justify-center items-center rounded-[4px] w-[100px] mx-auto;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.2s ease;
}

.slide-up-enter-from {
  opacity: 0;
}

.slide-up-leave-to {
  opacity: 0;
}
</style>
