<script setup lang="ts">
import SmallDropdown from "~/components/Common/Dropdowns/SmallDropdown.vue";
import UserIcon from "~/components/Header/UserIcon.vue";
import AccountDropdown from "~/components/Header/AccountDropdown.vue";
import { useAuthStore } from "~/store/auth/auth.store";
import SvgButton from "../Common/Buttons/SvgButton.vue";
import { useCompaniesStore } from "~/store/companies/companies.store";

const show_account = ref<boolean>(false);
const user_icon = ref();
const authStore = useAuthStore();
const dropdownPosition = ref({ top: 0, left: 0 });
const companiesStore = useCompaniesStore();
const { company_infos } = storeToRefs(companiesStore);

declare global {
  interface Window {
    Beacon: any;
  }
}

function openHelpscout() {
  window.Beacon("open");
}

const eventToggleOffDropdown = (event: FocusEvent) => {
  if (event?.relatedTarget instanceof HTMLElement) {
    if (event.relatedTarget.classList.contains("disconnect_button")) {
      return;
    }
  }
  show_account.value = false;
};

watch(show_account, (newVal) => {
  if (newVal) {
    const rect = user_icon.value.$el.getBoundingClientRect();
    dropdownPosition.value = { top: rect.bottom, left: rect.left - 150 };
  }
});


onMounted(async () => {
  await nextTick(async () => {
    await companiesStore.fetchCompanyInfos();
  });
});
</script>

<template>
  <div class="relative">
    <nav class="header">
      <div class="flex flex-row items-center px-6 lg:px-8 py-4 lg:py-0 lg:pt-2">
        <div class="w-full lg:hidden">
          <span class="heading_3_demi">Bienvenue 👋</span>
        </div>
        <div class="flex flex-row w-full justify-end gap-[8px] lg:gap-[16px] items-center">
          <SvgButton
            class="cursor-pointer text-secondary-color"
            name="tooltip"
            :style="{ border: 'none', height: '24px' }"
            @click="openHelpscout"
          ></SvgButton>
          <SmallDropdown></SmallDropdown>
          <UserIcon
            v-if="authStore.isLoggedIn()"
            @click="show_account = !show_account"
            @focusout="eventToggleOffDropdown"
            ref="user_icon"
          ></UserIcon>
        </div>
      </div>
      <div class="w-full px-[16px] px:mx-[24px] pb-5 border-b-[1px] border-stroke-color hidden lg:flex lg:flex-col items-center justify-start">
      <div class="relative max-w-[1250px] w-full">
        <div class="logo">
          <img v-show="company_infos?.logo" :src="company_infos?.logo" style="width: 98px; height: 98px;" alt="company_logo" />
        </div>
        <div class="pl-[138px] flex flex-col gap-3">
          <span class="heading_2_bold">Bienvenue{{ authStore.user?.firstname ?  ` ${authStore.user?.firstname?.charAt(0)?.toUpperCase()}${authStore.user?.firstname?.slice(1)?.toLowerCase()}` : "" }} ! 👋</span>
          <span class="body_med text-secondary-color">{{ company_infos?.welcome_message }}</span>
        </div>
      </div>
    </div>
    </nav>
    <Teleport to="body">
      <AccountDropdown
        v-if="authStore.isLoggedIn()"
        @close="show_account = false"
        :show_account="show_account"
        :style="{ top: dropdownPosition.top + 'px', left: dropdownPosition.left + 'px' }"
      ></AccountDropdown>
    </Teleport>
  </div>
</template>

<style scoped>
.header {
  @apply flex flex-col  gap-[18px];
  background: var(--white-main);
}

.logo {
  @apply absolute left-[0px] w-[118px] h-[118px] flex items-center justify-center;
  background: var(--white-main);
  border: 1px solid var(--grey-lightest);
  border-radius: 16px;
  top: 100%; 
  transform: translateY(-50%);
}
</style>
