<script setup lang="ts">
import NavBar from "~/components/Navbar/NavBar.vue";
import HomeActionsMobile from "~/components/Pages/home/HomeActionsMobile.vue";
import NavBarMobile from "~~/components/Navbar/NavBarMobile.vue";
import { useAuthStore } from "~/store/auth/auth.store";

const open = ref(false);
const authStore = useAuthStore();

const { $Localize } = useNuxtApp();

const typedLocalize: any = $Localize;

onMounted(async () => {
  await authStore.fetchProfile();

  if (authStore.isLoggedIn()) {
    typedLocalize.setLanguage(authStore.user?.language);
  }
});
</script>

<template>
  <div class="layout_container">
    <NavBar></NavBar>
    <NavBarMobile></NavBarMobile>

    <div id="right_container" class="right_container">
      <HeaderHome></HeaderHome>
      <div class="slot">
        <slot />
      </div>
    </div>
  </div>
  <div v-if="open" class="overlay" @click="(event: MouseEvent) => (open = false)"></div>

  <div class="home_menu md:hidden">
    <HomeActionsMobile :open="open" @update:open="(val) => (open = val)"> </HomeActionsMobile>
  </div>
  <FeedbackContainer></FeedbackContainer>
</template>

<style scoped>
.layout_container {
  @apply flex flex-row;
  min-height: 100%;
}

.right_container {
  margin-left: var(--navbar_width);
  overflow-x: hidden;
  width: 100%;
  background: var(--white-main);
}

.slot {
  min-width: 100vw - var(--navbar_width);
  height: calc(100vh - var(--header_home_height));
}

@media (max-width: 768px) {
  .slot {
    padding-right: 0px;
    padding-left: 0px;
    height: calc(100vh - 72px);
  }
}

@media (max-width: 768px) {
  .right_container {
    margin-left: 0;
  }
}

.home_menu {
  @apply sticky bottom-16 py-[16px] w-full bg-lightest-grey-color md:hidden rounded-t-lg;
  z-index: 101;
}
</style>
